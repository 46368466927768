import { createSlice } from '@reduxjs/toolkit'
import { getItem, setItem, tokenPaths, removeItem } from '../../utils/localStorage'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface InsuranceTestAutofillState {
    email: string | null
    policyId: string | null
}

export type StoreInputKeys = 'email' | 'policyId'

type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T
}

export type StoreInput = PartialRecord<StoreInputKeys, string>

export interface InsuranceTestReducerMethods {
    storeParameters: (payload: StoreInput) => any
    removeParameters: () => any
}

export function parseKeyFromInput(input: string): StoreInputKeys | null {
    const parsedKey = (input as string).toLowerCase().trim().replace(/_/gi, '').replace(/-/gi, '')
    switch (parsedKey) {
        case 'email':
            return 'email'
        case 'policyid':
        case 'policynumber':
        case 'policyno':
            return 'policyId'
        default:
            return null
    }
}

function updateLocalValue(key: string, value: string | undefined) {
    if (value != null) {
        setItem(key, value)
    } else {
        removeItem(key)
    }
}

function storeValuesLocally(input: StoreInput): InsuranceTestAutofillState {
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersEmail, input.email)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersPolicyId, input.policyId)
    return {
        email: input.email || null,
        policyId: input.policyId || null,
    }
}

function initializeState({} = {}): InsuranceTestAutofillState {
    return {
        email: getItem(tokenPaths.InsuranceAutoFillParametersEmail) || null,
        policyId: getItem(tokenPaths.InsuranceAutoFillParametersPolicyId) || null,
    }
}

function updateInPlace(state: InsuranceTestAutofillState, newState: InsuranceTestAutofillState) {
    for (const key of Object.keys(newState)) {
        state[key as StoreInputKeys] = newState[key as StoreInputKeys]
    }
}

const slice = createSlice({
    name: 'insuranceLockTests',
    initialState: initializeState(),
    reducers: {
        storeParameters: (state: InsuranceTestAutofillState, action: PayloadAction<StoreInput>) => {
            const newState = storeValuesLocally(action.payload)
            updateInPlace(state, newState)
        },
        removeParameters: (state: InsuranceTestAutofillState) => {
            const newState = storeValuesLocally({})
            updateInPlace(state, newState)
        },
    },
})

export const { storeParameters, removeParameters } = slice.actions

export default slice.reducer
