import { getItem, tokenPaths } from './localStorage'

const API_URL = process.env.GATSBY_API_URL

export function getBackendBaseUrl() {
    const localOverwrite = getItem(tokenPaths.backendBaseUrl)
    if (localOverwrite != null && localOverwrite.endsWith('bikekey.com')) {
        return localOverwrite
    }
    return API_URL
}
