import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { getStoredAuthToken } from '../../utils/auth-token'
import { getBackendBaseUrl } from '../../utils/backendConfig'

export const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, WebApi, extraOptions) => {
    const rawBaseQuery = fetchBaseQuery({
        baseUrl: getBackendBaseUrl(),
        prepareHeaders: (headers, { getState }) => {
            const token = getStoredAuthToken()
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            if (process.env.GATSBY_APP_ENV?.toLowerCase() === 'development') {
                headers.set('ngrok-skip-browser-warning', 'any value')
            }
            return headers
        },
    })
    return rawBaseQuery(args, WebApi, extraOptions)
}
