import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ItemDocument, ItemType, LockTypes } from '../../types/inventory'

export interface RegisterEquipmentStateParameters {
    documents: ItemDocument[]
    id: string | undefined
    item_type_memo: ItemType
    note: string | undefined | null
    manufacturer_name: string | undefined
    product_name: string | undefined
    frame_number_match: 'yes' | 'no' | undefined
    has_signature: 'yes' | 'no' | undefined
    plate_ids: string[]
    serial_numbers: string[]
    dna_paste_ids: string[]
    varefakta_number: string | undefined
    varefakta_number_draft: string | undefined
    lock_date: string | undefined
    physical_key_number: string | undefined
    lock_type_memo: LockTypes | undefined | null
}
export interface RegisterEquipmentState {
    parameters: RegisterEquipmentStateParameters
    errors: StateKeys[]
}

export type StateKeys =
    | 'documents'
    | 'id'
    | 'item_type_memo'
    | 'note'
    | 'varefakta_number'
    | 'varefakta_number_draft'
    | 'manufacturer_name'
    | 'product_name'
    | 'frame_number_match'
    | 'has_signature'
    | 'plate_ids'
    | 'serial_numbers'
    | 'dna_paste_ids'
    | 'lock_date'
    | 'physical_key_number'
    | 'lock_type_memo'

const initializeState = (): RegisterEquipmentState => {
    return {
        parameters: {
            documents: [],
            id: undefined,
            item_type_memo: 'battery',
            note: undefined,
            plate_ids: [],
            serial_numbers: [],
            dna_paste_ids: [],
            manufacturer_name: undefined,
            product_name: undefined,
            frame_number_match: undefined,
            has_signature: undefined,
            varefakta_number: undefined,
            varefakta_number_draft: undefined,
            lock_date: undefined,
            physical_key_number: undefined,
            lock_type_memo: undefined,
        },
        errors: [],
    }
}

const slice = createSlice({
    name: 'registerEquipment',
    initialState: initializeState(),
    reducers: {
        setState: (state: any, action: PayloadAction<RegisterEquipmentStateParameters>) => {
            return {
                ...state,
                parameters: action.payload,
            }
        },
        updateState: (
            state: any,
            action: PayloadAction<Partial<RegisterEquipmentStateParameters>>
        ) => {
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    ...action.payload,
                },
            }
        },
        addError: (state: any, action: PayloadAction<Partial<StateKeys>>) => {
            return {
                ...state,
                errors: [...state.errors, action.payload],
            }
        },
        removeError: (state: any, action: PayloadAction<Partial<StateKeys>>) => {
            return {
                ...state,
                errors: state.errors.filter((e: StateKeys) => e !== action.payload),
            }
        },
        removeAllErrors: (state: any) => {
            return {
                ...state,
                errors: [],
            }
        },
        reset: (state: any) => {
            return initializeState()
        },
    },
})

export const { setState, reset, updateState, addError, removeError, removeAllErrors } =
    slice.actions

export default slice.reducer
