import { createSlice } from '@reduxjs/toolkit'
import { getItem, setItem, tokenPaths, removeItem } from '../../utils/localStorage'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ChatsState {
    currentMessageSubjectId: string | null
    currentMessageContent: string | null
}

export interface StoreMessageInput {
    subjectId: string | null
    content: string | null
}

function storeValuesLocally(subjectId: string | null, content: string | null) {
    if (subjectId) {
        setItem(tokenPaths.currentMessageSubjectId, subjectId)
    } else {
        removeItem(tokenPaths.currentMessageSubjectId)
    }
    if (content) {
        setItem(tokenPaths.currentMessageContent, content)
    } else {
        removeItem(tokenPaths.currentMessageContent)
    }
}

const initializeState = ({} = {}): ChatsState => {
    const currentMessageSubjectId = getItem(tokenPaths.currentMessageSubjectId) || null
    const currentMessageContent = getItem(tokenPaths.currentMessageContent) || null
    return {
        currentMessageSubjectId,
        currentMessageContent,
    }
}

const slice = createSlice({
    name: 'chats',
    initialState: initializeState(),
    reducers: {
        storeMessage: (state: ChatsState, action: PayloadAction<StoreMessageInput>) => {
            storeValuesLocally(action.payload.subjectId, action.payload.content)
            state.currentMessageSubjectId = action.payload.subjectId
            state.currentMessageContent = action.payload.content
        },
        storeMessageSubjectId: (state: ChatsState, action: PayloadAction<string>) => {
            storeValuesLocally(action.payload, state.currentMessageContent)
            state.currentMessageSubjectId = action.payload
        },
        storeMessagecontent: (state: ChatsState, action: PayloadAction<string>) => {
            storeValuesLocally(state.currentMessageSubjectId, action.payload)
            state.currentMessageContent = action.payload
        },
    },
})

export const { storeMessage, storeMessageSubjectId, storeMessagecontent } = slice.actions

export default slice.reducer
