import * as dayjs from 'dayjs'
import { getItem, removeItem, setItem, tokenPaths, windowGlobal } from './localStorage'
import jwt_decode from 'jwt-decode'
import { User } from '../types/session'

export function nullify(data: any) {
    const newData = { ...data }
    for (const key of Object.keys(data)) {
        if (data[key] === 'None') {
            newData[key] = null
        }
    }
    return newData
}

function validateToken(token: string | null): boolean {
    if (token == null || token === 'null' || token === 'undefined') {
        return false
    }
    try {
        const sessionData = nullify(jwt_decode(token)) as User & { exp: number }
        const expiresAt = dayjs(new Date(sessionData.exp * 1000))
        if (dayjs().isAfter(expiresAt)) {
            return false
        }
    } catch {
        return false
    }
    return true
}

export const getStoredAuthToken = () => {
    const parameters = new URLSearchParams((windowGlobal as any)?.location?.search)
    const searchToken = parameters.get('auth_token')
    if (validateToken(searchToken)) {
        storeAuthToken(searchToken as string)
        return searchToken
    }
    return getItem(tokenPaths.authToken)
}

export const storeAuthToken = (token: string) => {
    if (validateToken(token)) {
        return setItem(tokenPaths.authToken, token)
    }
}

export const removeStoredAuthToken = () => {
    removeCurrentOrganisation()
    removeItem(tokenPaths.authToken)
}

export const getStoredAuthTokenAdmin = () => getItem(tokenPaths.authTokenAdmin)

export const storeAuthTokenAdmin = (token: string) => setItem(tokenPaths.authTokenAdmin, token)

export const removeStoredAuthTokenAdmin = () => removeItem(tokenPaths.authTokenAdmin)

export const setCurrentOrganisation = (organisation_id: string) =>
    setItem(tokenPaths.currentOrganisation, organisation_id)

export const getCurrentOrganisation = () => getItem(tokenPaths.currentOrganisation)

export const removeCurrentOrganisation = () => removeItem(tokenPaths.currentOrganisation)
