export const windowGlobal: any = typeof window !== 'undefined' && window

export type TokenPathKeys =
    | 'backendBaseUrl'
    | 'forceContentWrapper'
    | 'emailVerifyNotify'
    | 'authToken'
    | 'authTokenRevisor'
    | 'authTokenAdmin'
    | 'currentOrganisation'
    | 'smallMenu'
    | 'lastStickerLookup'
    | 'redirectAfterLogin'
    | 'currentMessageSubjectId'
    | 'currentMessageContent'
    | 'registerBikeValidatedFrameNumber'
    | 'registerBikeValidatedBikekeyId'
    | 'registerBikeUploadedFiles'
    | 'registerBikeCategory'
    | 'registerBikeType'
    | 'friendRef'
    | 'userRef'
    | 'resetPasswordEmail'
    | 'InsuranceAutoFillParametersEmail'
    | 'InsuranceAutoFillParametersFirstName'
    | 'InsuranceAutoFillParametersLastName'
    | 'InsuranceAutoFillParametersStreetName'
    | 'InsuranceAutoFillParametersCityName'
    | 'InsuranceAutoFillParametersZipCode'
    | 'InsuranceAutoFillParametersFloor'
    | 'InsuranceAutoFillParametersSide'
    | 'InsuranceAutoFillParametersDoor'
    | 'InsuranceAutoFillParametersMobile'
    | 'InsuranceAutoFillParametersPolicyId'
    | 'InsuranceAutoFillParametersFrameNumber'
    | 'InsurancePurchaseAutoFillParametersBikePrice'
    | 'InsurancePurchaseAutoFillParametersBikeType'
    | 'InsurancePurchaseAutoFillParametersBikeIsElectric'
    | 'InsurancePurchaseAutoFillParametersBikeIsElectric'
    | 'InsurancePurchaseAutoFillParametersBikePurchaseDate'
    | 'InsurancePurchaseAutoFillParametersBikePurchaseDate'
    | 'InsurancePurchaseBasket'
    | 'InsuranceCheckoutParametersName'
    | 'InsuranceCheckoutParametersEmail'
    | 'InsuranceCheckoutParametersIdentitySocialNumber'
    | 'InsuranceCheckoutParametersStreetAddress'
    | 'InsuranceCheckoutParametersCity'
    | 'InsuranceCheckoutParametersZipCode'
    | 'InsuranceCheckoutParametersFloor'
    | 'InsuranceCheckoutParametersSide'
    | 'InsuranceCheckoutParametersDoor'
    | 'InsuranceCheckoutParametersMobilePhone'
    | 'InsuranceCheckoutParametersOtherPhone'
    | 'InsuranceCheckoutParametersBankAccount'
    | 'InsuranceCheckoutParametersBankRegister'
    | 'InsuranceContentsWelcomeDisabled'
    | 'createStolenReportDraftValues'
    | 'lastInitiatedMitIDSessionType'
    | 'lastCompletedMitIDSession'

export const tokenPaths: Record<TokenPathKeys, string> = {
    backendBaseUrl: 'backendBaseUrl',

    forceContentWrapper: 'forceContentWrapper',

    emailVerifyNotify: 'emailVerifyNotify',
    authToken: 'authToken',
    authTokenRevisor: 'authTokenRevisor',
    authTokenAdmin: 'authTokenAdmin',
    currentOrganisation: 'currentOrganisation',
    smallMenu: 'smallMenu',
    lastStickerLookup: 'lastStickerLookup',
    redirectAfterLogin: 'redirectAfterLogin',
    currentMessageSubjectId: 'currentMessageSubjectId',
    currentMessageContent: 'currentMessageContent',
    registerBikeValidatedFrameNumber: 'registerBikeValidatedFrameNumber',
    registerBikeValidatedBikekeyId: 'registerBikeValidatedBikekeyId',
    registerBikeUploadedFiles: 'registerBikeUploadedFiles',
    registerBikeCategory: 'registerBikeCategory',
    registerBikeType: 'registerBikeType',
    friendRef: 'friendRef',
    userRef: 'userRef',
    resetPasswordEmail: 'resetPasswordEmail',

    InsuranceAutoFillParametersEmail: 'InsuranceAutoFillParametersEmail',
    InsuranceAutoFillParametersFirstName: 'InsuranceAutoFillParametersFirstName',
    InsuranceAutoFillParametersLastName: 'InsuranceAutoFillParametersLastName',
    InsuranceAutoFillParametersStreetName: 'InsuranceAutoFillParametersStreetName',
    InsuranceAutoFillParametersCityName: 'InsuranceAutoFillParametersCityName',
    InsuranceAutoFillParametersZipCode: 'InsuranceAutoFillParametersZipCode',
    InsuranceAutoFillParametersFloor: 'InsuranceAutoFillParametersFloor',
    InsuranceAutoFillParametersSide: 'InsuranceAutoFillParametersSide',
    InsuranceAutoFillParametersDoor: 'InsuranceAutoFillParametersDoor',
    InsuranceAutoFillParametersMobile: 'InsuranceAutoFillParametersMobile',
    InsuranceAutoFillParametersPolicyId: 'InsuranceAutoFillParametersPolicyId',
    InsuranceAutoFillParametersFrameNumber: 'InsuranceAutoFillParametersFrameNumber',

    InsurancePurchaseAutoFillParametersBikePrice: 'InsurancePurchaseAutoFillParametersBikePrice',
    InsurancePurchaseAutoFillParametersBikeType: 'InsurancePurchaseAutoFillParametersBikeType',
    InsurancePurchaseAutoFillParametersBikeIsElectric:
        'InsurancePurchaseAutoFillParametersBikeIsElectric',
    InsurancePurchaseAutoFillParametersBikePurchaseDate:
        'InsurancePurchaseAutoFillParametersBikePurchaseDate',
    InsurancePurchaseBasket: 'InsurancePurchaseBasket',

    InsuranceCheckoutParametersName: 'InsuranceCheckoutParametersName',
    InsuranceCheckoutParametersEmail: 'InsuranceCheckoutParametersEmail',
    InsuranceCheckoutParametersIdentitySocialNumber:
        'InsuranceCheckoutParametersIdentitySocialNumber',
    InsuranceCheckoutParametersStreetAddress: 'InsuranceCheckoutParametersStreetAddress',
    InsuranceCheckoutParametersCity: 'InsuranceCheckoutParametersCity',
    InsuranceCheckoutParametersZipCode: 'InsuranceCheckoutParametersZipCode',
    InsuranceCheckoutParametersFloor: 'InsuranceCheckoutParametersFloor',
    InsuranceCheckoutParametersSide: 'InsuranceCheckoutParametersSide',
    InsuranceCheckoutParametersDoor: 'InsuranceCheckoutParametersDoor',
    InsuranceCheckoutParametersMobilePhone: 'InsuranceCheckoutParametersMobilePhone',
    InsuranceCheckoutParametersOtherPhone: 'InsuranceCheckoutParametersOtherPhone',

    InsuranceContentsWelcomeDisabled: 'InsuranceContentsWelcomeDisabled',

    InsuranceCheckoutParametersBankAccount: 'InsuranceCheckoutParametersBankAccount',
    InsuranceCheckoutParametersBankRegister: 'InsuranceCheckoutParametersBankRegister',

    createStolenReportDraftValues: 'createStolenReportDraftValues',

    lastInitiatedMitIDSessionType: 'lastInitiatedMitIDSessionType',
    lastCompletedMitIDSession: 'lastCompletedMitIDSession',
}

export const cookieTrackingNames = {
    GA: 'gatsby-gdpr-google-analytics',
}

export const getItem = (path: string) => windowGlobal?.localStorage?.getItem(path)

export const setItem = (path: string, item: string) =>
    windowGlobal?.localStorage?.setItem(path, item)

export const removeItem = (path: string) => windowGlobal?.localStorage?.removeItem(path)
