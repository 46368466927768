import { createSlice } from '@reduxjs/toolkit'
import { getItem, setItem, tokenPaths, removeItem, TokenPathKeys } from '../../utils/localStorage'
import type { PayloadAction } from '@reduxjs/toolkit'
import * as dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

export interface InsurancePurchaseAutofillState {
    bikePrice?: number | null | undefined
    bikeType?: 'mtb' | 'racer' | 'tri' | 'gravel' | null | undefined
    bikeIsElectric?: string | null | undefined
    bikePurchaseDate?: string | null | undefined
}

export interface InsurancePurchaseCheckoutState {
    name?: string | null | undefined
    email?: string | null | undefined
    identitySocialNumber?: string | null | undefined
    streetAddress?: string | null | undefined
    city?: string | null | undefined
    zipCode?: string | null | undefined
    floor?: string | null | undefined
    side?: string | null | undefined
    door?: string | null | undefined
    mobilePhone?: string | null | undefined
    otherPhone?: string | null | undefined
    bankRegister?: string | null | undefined
    bankAccount?: string | null | undefined
}

export interface CreateBasketItemInput {
    bikePrice: number
    bikeType: string
    bikeIsElectric: string
    bikePurchaseDate: string
    frameNumber?: string | null | undefined
    subscriptionName: SubscriptionNames
}

export interface BasketItemState extends CreateBasketItemInput {
    basketItemId: string
}

export interface CheckoutParameterUpdateInput {
    key: CheckoutKeys
    value: string | null
}

export interface InsurancePurchaseBasketState {
    basketItems: BasketItemState[]
}

export interface InsurancePurchaseState {
    autofillState: InsurancePurchaseAutofillState
    basketState: InsurancePurchaseBasketState
    checkoutState: InsurancePurchaseCheckoutState
}

export interface UpdateBasketItemInput {
    basketItemId: string
    update: { key: BasketItemKeys; value: string | number | null }
}

export type SubscriptionNames = 'Motionist' | 'Super Motionist' | 'Elite'
export type StoreInputKeys = 'bikePrice' | 'bikeType' | 'bikeIsElectric' | 'bikePurchaseDate'
export type AddBasketItemKeys = StoreInputKeys | 'subscriptionName' | 'frameNumber'
export type BasketItemKeys = StoreInputKeys | 'frameNumber'
export type CheckoutKeys =
    | 'name'
    | 'email'
    | 'identitySocialNumber'
    | 'streetAddress'
    | 'city'
    | 'zipCode'
    | 'floor'
    | 'side'
    | 'door'
    | 'mobilePhone'
    | 'otherPhone'
    | 'bankAccount'
    | 'bankRegister'

const checkoutParameterLocalStorageKeyMap: Record<CheckoutKeys, TokenPathKeys> = {
    name: 'InsuranceCheckoutParametersName',
    email: 'InsuranceCheckoutParametersEmail',
    identitySocialNumber: 'InsuranceCheckoutParametersIdentitySocialNumber',
    streetAddress: 'InsuranceCheckoutParametersStreetAddress',
    city: 'InsuranceCheckoutParametersCity',
    zipCode: 'InsuranceCheckoutParametersZipCode',
    floor: 'InsuranceCheckoutParametersFloor',
    side: 'InsuranceCheckoutParametersSide',
    door: 'InsuranceCheckoutParametersDoor',
    mobilePhone: 'InsuranceCheckoutParametersMobilePhone',
    otherPhone: 'InsuranceCheckoutParametersOtherPhone',
    bankAccount: 'InsuranceCheckoutParametersBankAccount',
    bankRegister: 'InsuranceCheckoutParametersBankRegister',
}

export interface InsurancePurchaseReducerMethods {
    storeParameters: (payload: InsurancePurchaseAutofillState) => any
    removeParameters: () => any
}

function updateLocalValue(key: string, value: any) {
    if (value != null) {
        setItem(key, value.toString())
    } else {
        removeItem(key)
    }
}

function storeValuesLocally(input: InsurancePurchaseAutofillState): InsurancePurchaseAutofillState {
    updateLocalValue(tokenPaths.InsurancePurchaseAutoFillParametersBikePrice, input.bikePrice)
    updateLocalValue(tokenPaths.InsurancePurchaseAutoFillParametersBikeType, input.bikeType)
    updateLocalValue(
        tokenPaths.InsurancePurchaseAutoFillParametersBikeIsElectric,
        input.bikeIsElectric
    )
    updateLocalValue(
        tokenPaths.InsurancePurchaseAutoFillParametersBikePurchaseDate,
        input.bikePurchaseDate
    )
    return {
        bikePrice: input.bikePrice && !!Number(input.bikePrice) ? Number(input.bikePrice) : null,
        bikeType: input.bikeType?.toString() || null,
        bikeIsElectric: input.bikeIsElectric?.toString() || null,
        bikePurchaseDate: input.bikePurchaseDate?.toString(),
    }
}

function storeBasketLocally(basket: InsurancePurchaseBasketState): void {
    updateLocalValue(tokenPaths.InsurancePurchaseBasket, JSON.stringify(basket))
}

function getDefaultAutofill() {
    return {
        bikePrice: 15000,
        bikeType: 'racer',
        bikeIsElectric: 'false',
        bikePurchaseDate: dayjs().format('YYYY-MM-DD'),
    }
}

function initializeState({} = {}): InsurancePurchaseState {
    return {
        autofillState: {
            bikePrice: getItem(tokenPaths.InsurancePurchaseAutoFillParametersBikePrice) || 15000,
            bikeType: getItem(tokenPaths.InsurancePurchaseAutoFillParametersBikeType) || 'racer',
            bikeIsElectric:
                getItem(tokenPaths.InsurancePurchaseAutoFillParametersBikeIsElectric) || 'false',
            bikePurchaseDate:
                getItem(tokenPaths.InsurancePurchaseAutoFillParametersBikePurchaseDate) ||
                dayjs().format('YYYY-MM-DD'),
        },
        basketState: JSON.parse(
            getItem(tokenPaths.InsurancePurchaseBasket) || JSON.stringify({ basketItems: [] })
        ),
        checkoutState: {
            name: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.name]) || '',
            email: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.email]) || '',
            identitySocialNumber:
                getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.email]) || '',
            streetAddress:
                getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.streetAddress]) || '',
            city: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.city]) || '',
            zipCode: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.zipCode]) || '',
            floor: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.floor]) || '',
            side: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.side]) || '',
            door: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.door]) || '',
            mobilePhone: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.mobilePhone]) || '',
            otherPhone: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.otherPhone]) || '',
            bankRegister:
                getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.bankRegister]) || '',
            bankAccount: getItem(tokenPaths[checkoutParameterLocalStorageKeyMap.bankAccount]) || '',
        },
    }
}

function updateParametersInPlace(
    state: InsurancePurchaseState,
    newState: InsurancePurchaseAutofillState
) {
    for (const key of Object.keys(newState)) {
        state.autofillState[key as StoreInputKeys] = newState[key as StoreInputKeys] as any
    }
}

const slice = createSlice({
    name: 'insurancePurchase',
    initialState: initializeState(),
    reducers: {
        storeParameters: (
            state: InsurancePurchaseState,
            action: PayloadAction<InsurancePurchaseAutofillState>
        ) => {
            const newState = storeValuesLocally(action.payload)
            updateParametersInPlace(state, newState)
        },
        removeParameters: (state: InsurancePurchaseState) => {
            const newState = storeValuesLocally(getDefaultAutofill())
            updateParametersInPlace(state, newState)
        },
        storeAddItemToBasket: (
            state: InsurancePurchaseState,
            action: PayloadAction<CreateBasketItemInput>
        ) => {
            const newBasketItem = {
                ...action.payload,
                basketItemId: uuidv4(),
            }
            const basket: InsurancePurchaseBasketState = {
                ...state.basketState,
                basketItems: [...state.basketState.basketItems, newBasketItem],
            }
            storeBasketLocally(basket)
            const newState = storeValuesLocally(getDefaultAutofill())
            updateParametersInPlace(state, newState)
            state.basketState.basketItems.push(newBasketItem)
        },
        storeRemoveItemFromBasket: (
            state: InsurancePurchaseState,
            action: PayloadAction<string>
        ) => {
            state.basketState.basketItems.splice(
                state.basketState.basketItems.findIndex(
                    (item) => item.basketItemId === action.payload
                ),
                1
            )
            storeBasketLocally({
                ...state.basketState,
                basketItems: state.basketState.basketItems,
            })
        },
        updateBasketItemParameter: (
            state: InsurancePurchaseState,
            action: PayloadAction<UpdateBasketItemInput>
        ) => {
            let itemIndex = state.basketState.basketItems.findIndex(
                (bi) => bi.basketItemId === action.payload.basketItemId
            )
            state.basketState.basketItems[itemIndex] = {
                ...state.basketState.basketItems[itemIndex],
                [action.payload.update.key]: action.payload.update.value,
            }
            storeBasketLocally(state.basketState)
        },
        updateCheckoutParameter: (
            state: InsurancePurchaseState,
            action: PayloadAction<CheckoutParameterUpdateInput>
        ) => {
            state.checkoutState[action.payload.key] = action.payload.value
            updateLocalValue(
                checkoutParameterLocalStorageKeyMap[action.payload.key],
                action.payload.value
            )
        },
    },
})

export const {
    storeParameters,
    removeParameters,
    storeAddItemToBasket,
    storeRemoveItemFromBasket,
    updateBasketItemParameter,
    updateCheckoutParameter,
} = slice.actions

export default slice.reducer
