import { createSlice } from '@reduxjs/toolkit'
import { getItem, setItem, tokenPaths, removeItem } from '../../utils/localStorage'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface InsuranceVerificationAutofillState {
    email: string | null
    firstName: string | null
    lastName: string | null
    streetName: string | null
    cityName: string | null
    zipCode: string | null
    floor: string | null
    side: string | null
    door: string | null
    mobile: string | null
    policyId: string | null
    frameNumber: string | null
}

export type StoreInputKeys =
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'streetName'
    | 'cityName'
    | 'zipCode'
    | 'floor'
    | 'side'
    | 'door'
    | 'mobile'
    | 'policyId'
    | 'frameNumber'

type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T
}

export type StoreInput = PartialRecord<StoreInputKeys, string>

export interface InsuranceVerificationReducerMethods {
    storeParameters: (payload: StoreInput) => any
    removeParameters: () => any
}

export function parseKeyFromInput(input: string): StoreInputKeys | null {
    const parsedKey = (input as string).toLowerCase().trim().replace(/_/gi, '').replace(/-/gi, '')
    switch (parsedKey) {
        case 'email':
            return 'email'
        case 'first':
        case 'firstname':
            return 'firstName'
        case 'last':
        case 'lastname':
            return 'lastName'
        case 'street':
        case 'streetname':
            return 'streetName'
        case 'city':
        case 'cityname':
            return 'cityName'
        case 'zip':
        case 'zipcode':
            return 'zipCode'
        case 'floor':
            return 'floor'
        case 'side':
            return 'side'
        case 'door':
            return 'door'
        case 'mobile':
        case 'mobileno':
        case 'mobilenumber':
        case 'phone':
        case 'phoneno':
        case 'phonenumber':
            return 'mobile'
        case 'policyid':
        case 'policynumber':
        case 'policyno':
            return 'policyId'
        case 'framenumber':
            return 'frameNumber'
        default:
            return null
    }
}

function updateLocalValue(key: string, value: string | undefined) {
    if (value != null) {
        setItem(key, value)
    } else {
        removeItem(key)
    }
}

function storeValuesLocally(input: StoreInput): InsuranceVerificationAutofillState {
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersEmail, input.email)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersFirstName, input.firstName)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersLastName, input.lastName)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersStreetName, input.streetName)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersCityName, input.cityName)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersZipCode, input.zipCode)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersFloor, input.floor)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersSide, input.side)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersDoor, input.door)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersMobile, input.mobile)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersPolicyId, input.policyId)
    updateLocalValue(tokenPaths.InsuranceAutoFillParametersFrameNumber, input.frameNumber)
    return {
        email: input.email || null,
        firstName: input.firstName || null,
        lastName: input.lastName || null,
        streetName: input.streetName || null,
        cityName: input.cityName || null,
        zipCode: input.zipCode || null,
        floor: input.floor || null,
        side: input.side || null,
        door: input.door || null,
        mobile: input.mobile || null,
        policyId: input.policyId || null,
        frameNumber: input.frameNumber || null,
    }
}

function initializeState({} = {}): InsuranceVerificationAutofillState {
    return {
        email: getItem(tokenPaths.InsuranceAutoFillParametersEmail) || null,
        firstName: getItem(tokenPaths.InsuranceAutoFillParametersFirstName) || null,
        lastName: getItem(tokenPaths.InsuranceAutoFillParametersLastName) || null,
        streetName: getItem(tokenPaths.InsuranceAutoFillParametersStreetName) || null,
        cityName: getItem(tokenPaths.InsuranceAutoFillParametersCityName) || null,
        zipCode: getItem(tokenPaths.InsuranceAutoFillParametersZipCode) || null,
        floor: getItem(tokenPaths.InsuranceAutoFillParametersFloor) || null,
        side: getItem(tokenPaths.InsuranceAutoFillParametersSide) || null,
        door: getItem(tokenPaths.InsuranceAutoFillParametersDoor) || null,
        mobile: getItem(tokenPaths.InsuranceAutoFillParametersMobile) || null,
        policyId: getItem(tokenPaths.InsuranceAutoFillParametersPolicyId) || null,
        frameNumber: getItem(tokenPaths.InsuranceAutoFillParametersFrameNumber) || null,
    }
}

function updateInPlace(
    state: InsuranceVerificationAutofillState,
    newState: InsuranceVerificationAutofillState
) {
    for (const key of Object.keys(newState)) {
        state[key as StoreInputKeys] = newState[key as StoreInputKeys]
    }
}

const slice = createSlice({
    name: 'insuranceVerification',
    initialState: initializeState(),
    reducers: {
        storeParameters: (
            state: InsuranceVerificationAutofillState,
            action: PayloadAction<StoreInput>
        ) => {
            const newState = storeValuesLocally(action.payload)
            updateInPlace(state, newState)
        },
        removeParameters: (state: InsuranceVerificationAutofillState) => {
            const newState = storeValuesLocally({})
            updateInPlace(state, newState)
        },
    },
})

export const { storeParameters, removeParameters } = slice.actions

export default slice.reducer
