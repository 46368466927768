import { createSlice } from '@reduxjs/toolkit'
import { getItem, removeItem, setItem, tokenPaths } from '../../utils/localStorage'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface RegisterBikeDocumentsState {
    mainImage: string | null
    otherImages: (string | null)[]
    frameNumber: (string | null)[]
    receipt: (string | null)[]
    lockProof: (string | null)[]
}

export interface RegisterBikeInputState {
    validatedFrameNumber: string | null
    validatedBikekeyId: string | null
    uploadedFiles: RegisterBikeDocumentsState
    category: { id: string; label: string } | null
    type: { id: string; label: string } | null
}

function storeValidatedFrameNumberLocally(validatedFrameNumber: string | null) {
    if (validatedFrameNumber) {
        setItem(tokenPaths.registerBikeValidatedFrameNumber, validatedFrameNumber)
    } else {
        removeItem(tokenPaths.registerBikeValidatedFrameNumber)
    }
}

function storeValidatedBikekeyIdLocally(validatedBikekeyId: string | null) {
    if (validatedBikekeyId) {
        setItem(tokenPaths.registerBikeValidatedBikekeyId, validatedBikekeyId)
    } else {
        removeItem(tokenPaths.registerBikeValidatedBikekeyId)
    }
}

function storeUploadedFilesRefsLocally(uploadedFilesRefs: RegisterBikeDocumentsState) {
    setItem(tokenPaths.registerBikeUploadedFiles, JSON.stringify(uploadedFilesRefs))
}

function storeCategoryLocally(category: { id: string; label: string } | null) {
    if (category?.id) {
        setItem(tokenPaths.registerBikeCategory, JSON.stringify(category))
    } else {
        removeItem(tokenPaths.registerBikeCategory)
    }
}

function storeTypeLocally(type: { id: string; label: string } | null) {
    if (type?.id) {
        setItem(tokenPaths.registerBikeType, JSON.stringify(type))
    } else {
        removeItem(tokenPaths.registerBikeType)
    }
}

const initializeState = ({} = {}): RegisterBikeInputState => {
    const validatedFrameNumber = getItem(tokenPaths.registerBikeValidatedFrameNumber) || null
    const validatedBikekeyId = getItem(tokenPaths.registerBikeValidatedBikekeyId) || null
    const uploadedFilesString = getItem(tokenPaths.registerBikeUploadedFiles) || null
    let uploadedFiles = {
        mainImage: null,
        otherImages: [],
        frameNumber: [],
        receipt: [],
        lockProof: [],
    }
    if (uploadedFilesString && uploadedFilesString.length > 10) {
        uploadedFiles = JSON.parse(uploadedFilesString)
    }
    const categoryString = getItem(tokenPaths.registerBikeCategory) || null
    let category = null
    if (categoryString && categoryString?.length > 0) {
        category = JSON.parse(categoryString)
    }
    const typeString = getItem(tokenPaths.registerBikeType) || null
    let type = null
    if (typeString && typeString?.length > 0) {
        type = JSON.parse(typeString)
    }
    return {
        validatedFrameNumber,
        validatedBikekeyId,
        uploadedFiles,
        category,
        type,
    }
}

const slice = createSlice({
    name: 'registerBikeInput',
    initialState: initializeState(),
    reducers: {
        storeValidatedFrameNumber: (
            state: RegisterBikeInputState,
            action: PayloadAction<string | null>
        ) => {
            storeValidatedFrameNumberLocally(action.payload)
            state.validatedFrameNumber = action.payload
        },
        storeValidatedBikekeyId: (
            state: RegisterBikeInputState,
            action: PayloadAction<string | null>
        ) => {
            storeValidatedBikekeyIdLocally(action.payload)
            state.validatedBikekeyId = action.payload
        },
        storeUploadedFilesRefs: (
            state: RegisterBikeInputState,
            action: PayloadAction<RegisterBikeDocumentsState>
        ) => {
            storeUploadedFilesRefsLocally(action.payload)
            state.uploadedFiles = action.payload
        },
        storeCategory: (
            state: RegisterBikeInputState,
            action: PayloadAction<{ id: string; label: string } | null>
        ) => {
            storeCategoryLocally(action.payload)
            state.category = action.payload
        },
        storeType: (
            state: RegisterBikeInputState,
            action: PayloadAction<{ id: string; label: string } | null>
        ) => {
            storeTypeLocally(action.payload)
            state.type = action.payload
        },
    },
})

export const {
    storeValidatedFrameNumber,
    storeValidatedBikekeyId,
    storeUploadedFilesRefs,
    storeCategory,
    storeType,
} = slice.actions

export default slice.reducer
