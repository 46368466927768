import { configureStore, isRejectedWithValue, MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { webshopApi } from './services/webshop'
import { chatsApi } from './services/chats'
import bikekeyApi from './services/bikekeyApi'
import { navigateToIndex } from '../components/blocks/pageWrappers/pageMap'
import { removeStoredAuthToken } from '../utils/auth-token'

export const rtkQueryTokenRedirect: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action?.payload?.status === 401 && action?.payload?.data?.detail === 'invalid_token') {
            removeStoredAuthToken()
            navigateToIndex(13)
        }
    }

    return next(action)
}

const createStore = () => {
    const store = configureStore({
        reducer: rootReducer,
        devTools: process.env.GATSBY_APP_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(rtkQueryTokenRedirect)
                .concat(webshopApi.middleware)
                .concat(chatsApi.middleware)
                .concat(bikekeyApi.middleware),
    })
    setupListeners(store.dispatch)
    return store
}

type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore['getState']
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore['dispatch']

export default createStore
