import { combineReducers } from 'redux'
import { webshopApi } from './services/webshop'
import { chatsApi } from './services/chats'

import bikekeyApi from './services/bikekeyApi'
import chats, { ChatsState } from './reducers/chats'
import registerEquipment, { RegisterEquipmentState } from './reducers/registerEquipment'
import reportStolen, { ReportStolenState } from './reducers/reportStolen'
import registerBikeInput, { RegisterBikeInputState } from './reducers/registerBikeInput'
import editBikeInput, { EditBikeInputState } from './reducers/editBikeInput'
import insuranceVerification, {
    InsuranceVerificationAutofillState,
} from './reducers/insuranceVerification'
import insuranceLockTests, { InsuranceTestAutofillState } from './reducers/insuranceTest'

import insurancePurchase, { InsurancePurchaseState } from './reducers/insurancePurchase'

export interface RootState {
    chats: ChatsState
    registerEquipment: RegisterEquipmentState
    registerBikeInput: RegisterBikeInputState
    editBikeInput: EditBikeInputState
    insuranceVerification: InsuranceVerificationAutofillState
    insuranceLockTests: InsuranceTestAutofillState
    insurancePurchase: InsurancePurchaseState
    reportStolen: ReportStolenState
    webshopApi: any
    chatsApi: any
    bikekeyApi: any
}

const appReducer = combineReducers({
    chats,
    registerEquipment,
    registerBikeInput,
    editBikeInput,
    insuranceVerification,
    insuranceLockTests,
    insurancePurchase,
    reportStolen,
    [webshopApi.reducerPath]: webshopApi.reducer,
    [chatsApi.reducerPath]: chatsApi.reducer,
    [bikekeyApi.reducerPath]: bikekeyApi.reducer,
})

export const RESET_STATE = 'RESET_STATE'

const rootReducer = (state: RootState | undefined, action: any) => {
    if (action.type === RESET_STATE) {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer
