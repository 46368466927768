import { environments, httpPost } from './http'
import { isInstalled } from '../utils/deviceStatus'
import { Session } from './hooks/useAuth'
import { SessionConfigIdGetters } from '../actions/webshop'
import * as dayjs from 'dayjs'

const facebookPixId = '555206346771145'

export enum ActionAreas {
    PageVisit = 'page-visit',
    UserEvent = 'user-event',
    WebshopRedirect = 'go-to-webshop',
    PurchaseCopmlete = 'purchase-complete',
    Error = 'error',
}

type fbType = {
    event_name: string
    fb_event_id: string
    event_source_url: string
    pathname: string
    product_type?: SessionConfigIdGetters
}

export async function logAction(area: ActionAreas, type: string, data?: unknown) {
    trackFacebookEvent(area, type, data as fbType)
    await httpPost(`/actions/${area}/${type}?installed=${isInstalled()}`, data as object)
}

export function initPixel(session: Session | null) {
    if (typeof window !== 'undefined') {
        const trackFunction = (window as any).fbq
        if (trackFunction != null) {
            const fbUserData: Record<string, any> = {}
            if (session?.email) {
                fbUserData.em = session.email
                fbUserData.external_id = session.party_id
                if (session.birth_date) {
                    fbUserData.db = dayjs(session.birth_date).format('YYYYMMDD')
                }
                if (session.zip_code) {
                    fbUserData.zp = session.zip_code
                }
                if (session.phone) {
                    fbUserData.ph = session.phone
                }
            }
            trackFunction('init', facebookPixId, fbUserData)
            trackFunction('set', 'agent', 'tmgoogletagmanager', facebookPixId)
        }
    }
}

async function trackFacebookEvent(area: ActionAreas, type: string, data?: fbType) {
    if (!data?.fb_event_id) return
    if (typeof window !== 'undefined') {
        const trackFunction = (window as any).fbq
        if (trackFunction != null) {
            const fbData: Record<string, any> = data as Record<string, any>
            try {
                const eventMetaData: Record<string, string> = {
                    eventID: (data as { fb_event_id: string }).fb_event_id,
                }
                if (
                    process.env.GATSBY_APP_ENV &&
                    [environments.development, environments.staging].includes(
                        process.env.GATSBY_APP_ENV
                    )
                ) {
                    eventMetaData.test_event_code = 'TEST51063'
                }
                trackFunction('track', data.event_name, fbData, eventMetaData)
            } catch {
                // pass
            }
        }
    }
}
