import * as dayjs from 'dayjs'
import { loadSession, Session, useSessionIfExists } from '../apis/hooks/useAuth'
import {
    BikeCredit,
    BikeCreditResponse,
    SubscriptionSessionResponse,
    useCreateBikekeyPurchaseSessionQuery,
    useCreateCustomerPortalSessionMutation,
    useGetBikeCreditsQuery,
} from '../redux/services/webshop'
import { v4 as uuidv4 } from 'uuid'
import { getBikeCredits } from '../apis/webshop'

export type SessionConfigIdGetters = 'single' | 'family' | 'organisation'
export type SessionConfigIds = 'SINGLE' | 'FAMILY' | 'ORGANISATION'

type CreditsDataWithoutActions = Pick<
    CreditsData,
    | 'bikeCredits'
    | 'firstAvailableBikeCredit'
    | 'hasActiveSubscription'
    | 'currentPeriodEnd'
    | 'isVip'
> & { isLoading?: boolean }

export interface CreditsData {
    bikeCredits: BikeCredit[] | null
    firstAvailableBikeCredit: BikeCredit | undefined
    hasActiveSubscription: boolean
    currentPeriodEnd: dayjs.Dayjs | undefined
    isVip: boolean
    isLoading: boolean
    refetch: () => Promise<void>
    createCustomerPortalSession: () => Promise<SubscriptionSessionResponse>
}

export const sessionConfigIds: Record<SessionConfigIdGetters, SessionConfigIds> = {
    single: 'SINGLE',
    family: 'FAMILY',
    organisation: 'ORGANISATION',
}

export function useBikekeyCheckoutUrl(): {
    checkoutUrls: Record<SessionConfigIdGetters, string | undefined>
} {
    const { data: dataSingle } = useCreateBikekeyPurchaseSessionQuery(sessionConfigIds.single, {
        pollingInterval: 3600000,
    })
    const { data: dataFamily } = useCreateBikekeyPurchaseSessionQuery(sessionConfigIds.family, {
        pollingInterval: 3600000,
    })
    const { data: dataOrganisation } = useCreateBikekeyPurchaseSessionQuery(
        sessionConfigIds.organisation,
        {
            pollingInterval: 3600000,
        }
    )

    return {
        checkoutUrls: {
            single: dataSingle?.url,
            family: dataFamily?.url,
            organisation: dataOrganisation?.url,
        },
    }
}

function buildFakeCredits(session: Session): CreditsData {
    const bikeCredits: BikeCredit[] = []
    while (bikeCredits.length < 100) {
        bikeCredits.push({
            id: uuidv4(),
            bike_ownership_id: null,
            created_on: new Date(),
            modified_on: new Date(),
            party_id: session.party_id,
            subscription_reepay_id: uuidv4(),
        })
    }
    const currentPeriodEnd = dayjs().add(1, 'year')
    const hasActiveSubscription = true
    const firstAvailableBikeCredit = bikeCredits.find((credit) => credit.bike_ownership_id === null)
    return {
        bikeCredits,
        currentPeriodEnd,
        hasActiveSubscription,
        firstAvailableBikeCredit,
        isVip: true,
        isLoading: false,
        refetch: () => Promise.resolve(),
        createCustomerPortalSession: () => Promise.resolve({ session_url: '' }),
    }
}

function determineCreditDataFromResponseData(
    data: BikeCreditResponse | undefined,
    session: Session
): CreditsDataWithoutActions {
    if (
        data?.credits &&
        session.identity_party_id &&
        (session.identity_signed_up_at == null ||
            dayjs(session.identity_signed_up_at).isBefore(dayjs('2023-02-07')))
    ) {
        return {
            ...buildFakeCredits(session),
            isLoading: false,
        }
    }

    const hasVipCredit =
        data?.credits && data.credits.length > 0 && data?.current_period_end === null

    const hasActiveSubscription = hasVipCredit
        ? true
        : (data?.current_period_end && dayjs(data?.current_period_end).isAfter(dayjs())) || false
    const bikeCredits = data?.credits || null
    const firstAvailableBikeCredit =
        !!bikeCredits && hasActiveSubscription
            ? bikeCredits.find((credit) => !credit.bike_ownership_id)
            : undefined

    return {
        bikeCredits,
        firstAvailableBikeCredit,
        hasActiveSubscription,
        currentPeriodEnd: hasVipCredit
            ? dayjs().add(1, 'year')
            : data?.current_period_end && dayjs(data?.current_period_end),
        isVip: hasVipCredit || false,
    }
}

export function useBikeCredits(pollingInterval = 30000): CreditsData {
    const { session } = useSessionIfExists()
    const { data, refetch, isFetching, isLoading } = useGetBikeCreditsQuery(undefined, {
        pollingInterval,
    })
    const [createCustomerPortalSession] = useCreateCustomerPortalSessionMutation()
    const createCustomerPortalSessionGetter = async () => {
        const response = await createCustomerPortalSession(undefined)
        return (response as { data: SubscriptionSessionResponse })?.data
    }

    return {
        ...determineCreditDataFromResponseData(data, session),
        isLoading: data?.credits === undefined || isFetching || isLoading,
        refetch: refetch as any,
        createCustomerPortalSession: createCustomerPortalSessionGetter,
    }
}

export async function loadBikeCreditsOnce(): Promise<CreditsDataWithoutActions> {
    return determineCreditDataFromResponseData(await getBikeCredits(), loadSession())
}
