import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface EditBikeInputState {
    newNickname: string
    newFrameNumber: string
    newPrice: number | null
}

const initializeState = ({} = {}): EditBikeInputState => {
    return {
        newNickname: '',
        newFrameNumber: '',
        newPrice: null,
    }
}

const slice = createSlice({
    name: 'editBikeInput',
    initialState: initializeState(),
    reducers: {
        setNewNickname: (state: EditBikeInputState, action: PayloadAction<string>) => {
            state.newNickname = action.payload
        },
        setNewFrameNumber: (state: EditBikeInputState, action: PayloadAction<string>) => {
            state.newFrameNumber = action.payload
        },
        setNewPrice: (state: EditBikeInputState, action: PayloadAction<number | null>) => {
            state.newPrice = action.payload
        },
    },
})

export const { setNewNickname, setNewFrameNumber, setNewPrice } = slice.actions

export default slice.reducer
