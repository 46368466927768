import { BikeCreditResponse } from '../redux/services/webshop'
import { httpGet, httpPost } from './http'

export async function placeOrderReepay(body: any): Promise<any> {
    return httpPost<{ document_reference_id: string }>(`/webshop/orders/reepay`, body)
}

export async function completeBikekeyPurchase(sessionId: string): Promise<string | null> {
    const response = await httpPost<{ url: string }>(`/webshop/orders/complete`, {
        session_id: sessionId,
    })
    return response?.data?.url || null
}

export async function getHasServiceProviders(bikeId: string): Promise<boolean> {
    const response = await httpPost<{ has_products: boolean }>(
        `/webshop/bike-services/${bikeId}`,
        {}
    )
    return response?.data?.has_products || false
}

export async function createOrganisationSubscriptionLink(): Promise<string | null> {
    const response = await httpPost<{ link: string }>(`/webshop/bike-transfer-subscriptions`, {})
    return response?.data?.link || null
}

export async function getBikeCredits(): Promise<BikeCreditResponse | undefined> {
    const response = await httpGet<BikeCreditResponse>(`/webshop/bike-activations`)
    return response?.data
}

export async function getBikeVoucherPartner(): Promise<
    | {
          name: string
          email: string
          party_id: string
      }
    | null
    | undefined
> {
    const response = await httpGet<{
        partner: { name: string; email: string; party_id: string } | null
    }>(`/webshop/partner-origin`)
    return response?.data?.partner
}

export async function redeemPlateId(
    plateId: string
): Promise<{ credit_id: string; product_memo: string }> {
    const result = await httpPost('/webshop/bike-ownership-credits', {
        plate_id: plateId,
    })
    const data = (
        result as {
            data: { credit_id: string; product_memo: string }
        }
    )?.data
    return data
}

export async function activateCredit(creditId: string, bikeOwnershipId?: string): Promise<string> {
    const result = await httpPost<{ bike_id: string }>(
        `/webshop/bike-ownership-credits/${creditId}/activations`,
        {
            bike_ownership_id: bikeOwnershipId,
        }
    )
    if (!result?.data?.bike_id) {
        throw new Error('something went wrong')
    }
    return result?.data?.bike_id
}
